<template>
    <div class="product">

      <!--TopBanner-->
      <section>
        <div class="topBanner d-flex align-center">
      </div>
      </section>

      <!--Details-->
      <section>
        <v-row class="gridpad">
          <v-col>
            <h2 class="mt-6" v-html="$t('res.address')"></h2>
          </v-col>
        </v-row>
      </section>
      <section class="gridpadForm">
        <v-row>
          <v-col><h2 class="f-primary txt-c">Découvrez nos appartements soigneusement aménagés pour répondre à toutes vos exigences.</h2></v-col>
        </v-row>
      </section>

      <section>
        <v-row style="" class="detailsMobile">
          <v-col>
            <infoDetails v-model:item="Content.Description"/>
          </v-col>
        </v-row>
        
      </section>

      <!--Images-->
      <section>
        <v-row class="gridpad">
          <v-col class="d-flex justify-center align-center">
            <h2 class="uppercase" v-html="$t('res.imagesTitle')"></h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col style="max-width: 1300px; margin-left: auto; margin-right: auto;">
            <rooms v-model:item="Content.ListImages"/>
          </v-col>
        </v-row>

        
      </section>

      <!--Description-->
      <section>
        <v-row class="gridpad">
          <v-col><h2 class="f-primary d-flex justify-sm-start justify-center" v-html="$t('description.title')"></h2></v-col>
        </v-row>
        
        <v-row class="gridpadForm">
          <v-col cols="12" md="6" class="txt-j px-6 ">
            <p class="roboto400 describeText"> Cet appartement 5 et demi, prêt à être occupé à partir de mars 2024, offre un cadre de vie confortable. De plus, la hauteur du plafond de l'appartement au rez-de-chaussée est de neuf pieds et demi.</p>
            <p class="roboto400 describeText"><span class="bold">Chambres spacieuses</span> : Les trois chambres de cet appartement sont généreusement dimensionnées, offrant un espace intime pour chaque membre de votre foyer. </p>
            <p class="roboto400 describeText"><span class="bold">Salle de bain fonctionnelle </span> : La salle de bain bien aménagée comprend toutes les commodités nécessaires pour votre routine quotidienne. Elle est conçue pour être pratique et fonctionnelle.</p>
            <p class="roboto400 describeText"><span class="bold">Balcon privé</span> : Profitez d'un espace extérieur privé avec un balcon où vous pourrez vous détendre, prendre un café le matin ou admirer le coucher de soleil en soirée.</p>
            <p class="roboto400 describeText"><span class="bold">Plancher chauffant</span> : les loyers à l'étage bénéficient d'un plancher chauffant dans les salles de bain pour un confort supplémentaire. Le loyer au rez de chaussée bénéficient d'un plancher chauffant dans toute l'appartement pour un confort supplémentaire</p>
          </v-col>
          <v-col cols="12" md="6" class="txt-j px-6">
            <p class="roboto400 describeText"><span class="bold">Stationnement avec borne électrique</span> : À l'arrière de l'immeuble, vous disposerez d'un espace de stationnement équipé d'une borne électrique.</p>
            <p class="roboto400 describeText"><span class="bold">Entretien</span> : Le déneigement de l'allée et l'entretien paysager sont inclus, vous offrant ainsi une tranquillité d'esprit tout au long de l'année.</p>
            <p class="roboto400 describeText"><span class="bold">Climatisation </span> : Les loyers, tant au rez-de-chaussée qu'à l'étage, sont équipés de systèmes de climatisation.</p>
            <p class="roboto400 describeText"><span class="bold">Conditions de location</span> :</p>
              <ul class="gridpadList">
                <li><p>Animaux non autorisés</p></li>
                <li><p>Fumeurs non autorisés</p></li>
              </ul>
            <p class="roboto400 describeText">Cet appartement représente une opportunité idéale pour ceux qui cherchent à vivre dans un espace spacieux, moderne et bien situé, avec des fonctionnalités avancées telles que le plancher chauffant, la climatisation et la recharge de véhicules électriques. Pour plus de détails ou pour réserver une visite, n'hésitez pas à nous contacter.</p>
          </v-col>
        </v-row>
      </section>
      
      <!--Formulaire-->
      <section class="gridpadForm" id="FormHome">
        <formulaire v-model:item="Content.form"/>
      </section>
  
  </div>
</template>
  
<style scoped>
  .detailsMobile {margin: 50px -70px}
  .borderPrimary { border: 7px solid rgb(var(--primary)) !important;}
  .borderPrimary:hover { box-shadow:0px 0px 10px black;}
  .borderKey { border-top: 1px solid rgb(var(--primary)) !important; border-bottom: 1px solid rgb(var(--primary)) !important; height:150px;}
  .borderKeyR { border-right: 1px solid rgb(var(--primary)) !important; height:80%; margin: 15px 0px !important;}
  .topBanner {position: relative; height: 500px;}
  .topBanner::before {
    content: ' '; 
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background-image: url('../../../public/ressources/images/building.jpg');
    background-position: center;
    background-position-y: 55%;
    background-repeat: no-repeat; 
    background-size: cover; 
  }
  .bgBlack {
    position:relative ; background-color: rgba(0,0,0,0); z-index:1000; width:100%; margin: 0vw 0px 0vw 0px;
  }
  .topBannerLogo {
    color: rgb(var(--tierce));
    text-shadow: 4px 4px 4px #BDA947;
    font-size: calc(7vw) !important;
    font-weight: 600;
    line-height: normal;
  }
  .describeText {text-align: justify; font-size: 20px; font-style: normal; font-weight: 300; line-height: normal; padding: 20px 0px;}
  .gridpadList{padding: 0px 50px;}

@media handheld, only screen and (max-width: 400px) {
    .detailsMobile {margin: 50px 0px !important;}
  }
  @media handheld, only screen and (max-width: 600px) {
    .detailsMobile {margin: 50px -30px;}
  }
  @media handheld, only screen and (max-width: 960px) {
    .topBanner {height: 250px;}
    .topBanner::before {
    width: 160%;
    background-position-y: 50%;

}
  }
  @media handheld, only screen and (max-width: 1264px) {}  
</style>

<script>
  // @ is an alias to /src
  import translations from '../../assets/i18n/residentialStPierre.json';
  import formulaire from '../../components/form.vue';
  import rooms from '../../components/rooms.vue';
  import infoDetails from '../../components/details.vue';
  
  export default {
    name: 'resTest',
    data: () => ({
      Content: {
        address: '275 chemin yamaska, saint-germain-de-grantham QC',
        type:'Residential', // Residential, Commercial
        img:'ressources/images/maisonDiapo.jpg',
        style:'Appartement',
        detailsTitle: {
          rooms: 'Chambres',
          bathrooms: 'Salles de bain',
          pets: 'Animaux',
          smoker: 'Fumeur'
        },
        Description: {
          type:'Residential',
          items: [
            { title: 'Chambres', details: '3'},
            { title: 'Salles de bain', details: '1'},
            { title: 'Animaux', details: 'interdit'},
            { title: 'Fumeur', details: 'Non fumeur'},  
          ]
        },
        ListImages: [
          {id: 0, price:'1575$', loan: true, type: 'Appartement rez de chaussée', img:'ressources/images/locations/StPierre/appartement1.jpg'},
          {id: 1, price:'1495$', loan: false, type: 'Appartement étage', img:'ressources/images/locations/StPierre/appartement2.jpg'},
          {id: 2, price:'1495$', loan: false, type: 'Appartement étage', img:'ressources/images/locations/StPierre/appartement3.jpg'},
        ],
        webLink:'/resTest',
        plan: 'ressources/images/plan.jpg',
        form: [
          {title: 'Appartement rez de chaussée (loué)'},
          {title: 'Appartement étage'}
        ]
      },
      
    }),
    mounted() {
      this.$i18n.mergeLocaleMessage('fr', translations.fr);
      this.$i18n.mergeLocaleMessage('en', translations.en);
    },
    methods:{
    },
    components: {
      formulaire,
      rooms,
      infoDetails
    }
  }
</script>
  