<template>
    <div v-if="ready">
      <v-row>
        <v-col class="pa-0" style="position:relative; max-width:15%;">
          <div style="height:185px; width:185px; position:absolute; right:-93px;">
            <img v-if="current.type == 'Commercial'" src="/ressources/images/house-key-svgrepo-res.svg" style="height:100%; width:100%"/>
            <img v-if="current.type == 'Residential'" src="/ressources/images/house-key-svgrepo-com.svg" style="height:100%; width:100%"/>
          </div>
        </v-col>
        <v-col>
          <v-carousel cycle :show-arrows="false" hide-delimiters infinite style="max-height: 150px; " class="borderKey">
          <template  v-for="(item, index) in current.items"> 
            <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index">
              <v-row style="height:150px" class="">
                <template v-for="(n,i) in columns"> 
                  <template v-if="(+index + i) < current.items.length"> 
                    <v-col :key="i" class="pa-0 d-flex justify-center align-center borderKeyR">
                      <v-sheet v-if="(+index + i) < current.items.length" height="100%" class="pt-5  d-flex justify-center align-center">
                        <v-row justify="center" class="ma-0">
                            <!--ici ton image ou information necessaire-->
                            <v-container class="pa-0" style=""> <!--v-if="item.id != 3"  v-if="item.promo == false"-->
                              <v-row class="ma-0 d-flex align-center">
                                <v-col class="pa-0 d-flex align-center justify-center">
                                  <div class="" style="width: 100%; margin-top: -15px;">
                                    <p class="txt-c f-primary bold" v-html="current.items[index + i].title"></p>
                                    <p class="txt-c" v-html="current.items[index + i].details"></p>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-container>
                        </v-row>
                        
                      </v-sheet>
                      
                    </v-col>
                  </template>
                </template>
              </v-row>
            </v-carousel-item>
          </template>
        </v-carousel>
        </v-col>
        <v-col class="pa-0" style="position:relative; max-width:15%;">
            <div style="height:185px; width:185px; position:absolute; left:-93px;">
              <img v-if="current.type == 'Commercial'" src="/ressources/images/house-key-svgrepo-res-reverse.svg" style="height:100%; width:100%"/>
              <img v-if="current.type == 'Residential'" src="/ressources/images/house-key-svgrepo-com-reverse.svg" style="height:100%; width:100%"/>
            </div>
          </v-col>
      </v-row>

      </div>
  </template>
  <style scoped>
    .borderKey { border-top: 1px solid rgb(var(--primary)) !important; border-bottom: 1px solid rgb(var(--primary)) !important; height:150px;}
    .borderKeyR { border-right: 1px solid rgb(var(--primary)) !important; margin-top : 25px;}
      .borderPrimary { border: 7px solid rgb(var(--primary)) !important;}
      .storeGridpad {padding: 25px 25px 0px 25px;}
      .product {  height: auto; width: auto; position: relative; background-image: url('../../public/ressources/images/maisonDiapo.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover; display: block; margin: auto auto !important; cursor: pointer; overflow: hidden;}
      .productContainer {max-height: 1000px; min-height: 400px; overflow-x: scroll;}
      .productContainer::-webkit-scrollbar { width: 1px !important;}
      .productTitleBox {position: absolute; background-color: rgba(var(--tierce), 0.6); width: auto; height: auto; z-index: 100; padding:5px 10px; top:10px;}
      .productTitle {font-family: Playfair Display; font-size: 20px; font-style: normal; font-weight: 600; line-height: normal;}
      .product {  height: auto; width: auto; position: relative; background-image: url('../../public/ressources/images/maisonDiapo.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover; display: block; margin: auto auto !important; cursor: pointer; overflow: hidden;}
      .productBox {height: 100%; width: 100%; min-width: 325px; max-width: 375px; padding: 0px; cursor: pointer;}
      .productHover{position: absolute; background-color: rgba(var(--tierce), 0.6); width: 100%; height: 30%; visibility: hidden; z-index: 100; bottom:0px}
      .styleProductHover{width: 100%; margin:5px;}
      .detailsProductHover{width: 100%;}
      .productBox:hover .productHover{visibility: visible;}
      @media handheld, only screen and (max-width: 600px) {}
      @media handheld, only screen and (max-width: 960px) {}
      @media handheld, only screen and (max-width: 1264px) {}  
  </style>
  <script>
      import pageTranslations from '../../public/ressources/i18n/global.json';
  
    export default {
      name: 'Locations',
      props: {
          item: Object
      },
      data: () => ({
        columns: 4,
        current: null,
      }),
      mounted() {
        this.changeColumns();
        this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
        this.$i18n.mergeLocaleMessage('en', pageTranslations.en);
  
      },
      created() {
        window.addEventListener("resize", this.changeColumns);
      },
      destroyed() {
        window.removeEventListener("resize", this.changeColumns);
      },
      computed:{
          ready: async function () {
              let show = false;
              if(this.item) {
                  this.current = this.item;
                  show = true;
              }
              return show;
          },
  
      },
      methods:{
        changeColumns() {
          let width = window.innerWidth;
          this.columns = parseInt(width/300);
          /*
          console.log(parseInt(width/300))
          if(width > 1600) { this.columns = 4; }
          else if (width > 1200) { this.columns = 3; }
          else if (width > 800) { this.columns = 2; } 
          else if (width < 800) { this.columns = 1; }*/
        }
      },
      component: {
  
      }
      
    }
  </script>