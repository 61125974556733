import { createRouter, createWebHistory } from 'vue-router'
import homeView from '../views/home.vue'
import resTest from '../views/places/resTest.vue'
import commercial from '../views/places/commercial.vue'
import residential from '../views/places/residential.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: residential
  },
  {
    path: '/commercial',
    name: 'commercial',
    component: commercial
  },
  {
    path: '/residential',
    name: 'residential',
    component: residential
  },
  {
    path: '/resTest',
    name: 'resTest',
    component: resTest
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, SavedPosition) {
    if (to.hash) {
      const el = window.location.href.split("#")[1];
      if (el.length) {
        document.getElementById(el).scrollIntoView({ behavior: "smooth" });
      }
    } else if (SavedPosition) {
      return SavedPosition;
    } else {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    }
  },
})

export default router
