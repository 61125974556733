<template>
    <div v-if="ready">
      <v-row>
        <v-col cols="12" class="d-flex align-center justify-center py-10">
          <h2 class="">{{ $t('form.title') }}</h2>
        </v-col>
      </v-row>

      <!--Form-->
            <v-row>
              <v-col cols="12">
                <form  ref="form">
                  <!--Form-->
                  <v-row>
                    <!--Form col 1-->
                    <v-col cols="12" lg="6" class="px-5">
                        <v-row class="">

                        <!--name-->
                          <v-col cols="12" class="">
                            <p class="bold f-secondary" v-if="!error.name" style="font-size: 14px;" v-html="$t('form.name')"></p>
                            <p class="bold error" v-if="error.name" style="font-size: 14px;" v-html="$t('form.name')"></p>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field @change="error.name = false" id="nom" name="nom" type="text" v-model="form.name" bg-color="amber-lighten-5" clearable required />
                            <p class="errorText" v-if="error.name" style="font-size: 14px; margin-top: -25px;">** Un nom est requis**</p>
                          </v-col>


                        <!--phone-->
                        <v-col cols="12">
                            <p class="bold f-secondary" v-if="!error.phone" style="font-size: 14px;" v-html="$t('form.phone')"></p>
                            <p class="bold error" v-if="error.phone" style="font-size: 14px;" v-html="$t('form.phone')"></p>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field @change="error.phone = false" label="xxxxxxxxxx" id="Telephone" name="Telephone" single-line @input="formatPhoneNumber" v-model="form.phone" bg-color="amber-lighten-5" />
                            <p class="errorText" v-if="error.phone" style="font-size: 14px; margin-top: -25px;">Un téléphone est requis **</p>
                          </v-col>
                        </v-row>
                    </v-col>
                    
                    <!--Form col 2-->
                    <v-col cols="12" lg="6" class="px-5">
                        <v-row>
                          <!--rent-->
                          <v-row class="ma-0">
                            <v-col cols="12" >
                              <p class="bold f-secondary" v-if="!error.rent" style="font-size: 14px;" v-html="$t('form.rent')"></p>
                                <p class="bold error" v-if="error.rent" style="font-size: 14px;" v-html="$t('form.rent')"></p>
                            </v-col>
                            <v-col cols="12">
                              <v-select @change="error.rent = false" id="Localisation" name="Localisation" type="text" bg-color="transparent" v-model="form.location" :items="ListRent" :item-props="itemProps" class="validColor"/>
                              <p class="errorText" v-if="error.rent" style="font-size: 14px; margin-top: -25px;">** Un endroit doit être choisi**</p>
                            </v-col>
                          </v-row>
                            <!--email-->
                            <v-col cols="12">
                              <p class="bold f-secondary" v-if="!error.email" style="font-size: 14px;" v-html="$t('form.mail')"></p>
                              <p class="bold error" v-if="error.email" style="font-size: 14px;" v-html="$t('form.mail')"></p>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field @change="error.email = false" id="Courriel" name="Courriel" type="text" v-model="form.email" bg-color="amber-lighten-5" clearable required />
                              <p class="errorText" v-if="error.email" style="font-size: 14px; margin-top: -25px;">** Une adresse e-mail est requise**</p>
                            </v-col>
                        </v-row>
                    </v-col>

                    <!--Form col 3-->
                    <v-col cols="12" class="px-5">
                        <!--describe-->
                        <v-row>
                          <v-col cols="12"><p class="bold f-secondary" style="font-size: 14px;" v-html="$t('form.message')"></p></v-col>
                          <v-col cols="12"><v-textarea clearable required no-resize id="commentaire" name="commentaire" v-model="form.Message" label="Description"  bg-color="amber-lighten-5" rows="3" row-height="45" single-line :counter="520" maxlength="520"/></v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12" class="px-5">
                        <!--authorization-->
                        <v-row>
                            <v-col cols="12">
                                <label class="container" >
                                  <p class="bold errorText" v-if="error.checkbox" style="font-size: 14px;" v-html="$t('form.authorizationError')"></p>
                                    <p class="bold f-secondary" v-if="!error.checkbox" style="font-size: 14px;" v-html="$t('form.authorization')"></p>
                                    <input type="checkbox" id="authorisation" name="authorisation"  v-model="form.checkbox" @change="error.checkbox = false">
                                    <span class="checkmark"></span>
                                    
                                </label>
                            </v-col>
                        </v-row>
                    </v-col>

                  </v-row>

                   <!--accept form-->
                   <v-row class="d-flex flex-lg-row flex-column">
                    <v-col cols="12" lg="6">

                    </v-col>
                    <v-col cols="12" lg="6" class="d-flex align-center justify-md-end justify-center">
                      <v-btn v-if="this.Dialog != 'send'" @click="this.SendForm();" class="button" style="margin-right:15px;">                 
                        <span class="buttonText2" v-if="this.Dialog == 'pending'">{{ $t('form.wait') }}</span>
                        <span class="buttonText2" v-else >{{ $t('form.send') }}</span>
                      </v-btn>
                      
                    </v-col>
                    <v-row>
                      <v-col>
                        <p v-if="this.Dialog == 'error'" class="" style="color: red; margin-left:15px;" >{{ $t('form.error') }}</p>
                        <p v-if="this.Dialog == 'invalid'" class="" style="color: red; margin-left:15px;" >Les informations données sont incomplètes ou invalid.</p>
                        <h3 v-if="this.Dialog == 'send'" class="f-primary" style="text-align: center; font-size: 2em;">{{ $t('form.complete') }}</h3>
                      </v-col>
                    </v-row>
                  </v-row>

                </form>
              </v-col>
            </v-row>

        </div>
</template>
<style scoped>
/* The container */
.button {padding: 0px 10px;}
.noValidColor {background-color: lightgray !important; height:72%;}
.validColor {background-color: #FFF8E1 !important; height:72%;}
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #FFF8E1;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: black;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 3px;
  width: 8px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

    @media handheld, only screen and (max-width: 600px) {}
    @media handheld, only screen and (max-width: 960px) {}
    @media handheld, only screen and (max-width: 1264px) {}  
</style>
<script>
    import pageTranslations from '../../public/ressources/i18n/form.json';
    import phone from '@/components/commons/Phone.vue';
    import { required, email, minLength } from '@vuelidate/validators'
    import { useVuelidate } from '@vuelidate/core';

  export default {
    name: 'Formulaire',
    props: {
      item: Object
    },
    data: () => ({
      current: null,
      form: {
        name: null,
        email: null,
        phone: null,
        Message: null,
        checkbox: null,
        location: null,
      },
      Dialog: "notSend", //"error, send, notSend, invalid"
      error:{
        name: false,
        email: false,
        phone: false,
        rent: false,
        Message: false,
        checkbox: false,
      },

      ListRent: [
        {rent: 'locale commerciale'},
        {rent: 'Apppartement rez de chaussée'},
        {rent: 'Apppartement étage'},
      ],
      key: "", //need to add key
    }),

    mounted() {
      this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      this.$i18n.mergeLocaleMessage('en', pageTranslations.en);
    },
    computed: {
      ready: async function () {
            let show = false;
            if(this.item) {
                this.ListRent = this.item;
                show = true;
            }
            return show;
        },
    },
    validations: {
      form: {
        name: { required },
        email: { required, email },
        phone: { required, min: minLength(7) },
        Message: { required },
        checkbox: null,
        location: { required },
      }
      
    },
    methods:{
        validation: async function(){
            let valid = true;
            let errorFlag = {name: false, email: false, phone: false, birthday: false, address: false, rent: false, checkbox: false};
            if(this.form.name == null || this.form.name.length == 0){ valid = false; errorFlag.name = true; }
            if(this.form.email == null || this.form.email.length == 0){ valid = false; errorFlag.email = true; }
            if(this.form.phone == null || this.form.phone.length < 6){valid = false; errorFlag.phone = true; }
            if(this.form.location == null || this.form.location == false){ valid = false; errorFlag.rent = true; }
            if(this.form.checkbox == null || this.form.checkbox == false){ valid = false; errorFlag.checkbox = true; }
            this.error = errorFlag;
            return valid;
        },
        login: async function () {

            let api = 'https://abstracxion.wx3data.com/security/login/GESTLOC9127';
            let data = null;
            try {
                let response = await fetch(api, {
                    method: 'PUT', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer', // no-referrer, *client
                    body: JSON.stringify({LoginName: "", password: ""}) // body data type must match "Content-Type" header
                })
                if (await response.ok) {
                    if (await response.status !== 204) {
                        let data = await response.text();
                        return data
                    }
                } else {
                    let msg = await response.text();
                    $App.event.$emit("app_error", msg);
                };
        
            } catch (error) {
                console.log(error);
            }
        },
        SendForm: async function (){
            this.Dialog = "pending";
            let valid = await this.validation();
            if(valid == true){
                let formData = new FormData(this.$refs.form);

                let api = 'https://abstracxion.wx3data.com/eCommerce/Forms/ContactUs/sendByEmail';
                try {
                    let token = await this.login();

                    // ** ATTENTION need to add bearer token...
                    let response = await fetch(api, {
                        method: 'POST',
                        mode: 'cors', // no-cors, *cors, same-origin
                        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                        credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Authorization': 'Bearer ' + token
                        },
                        redirect: 'follow', // manual, *follow, error
                        referrerPolicy: 'no-referrer', // no-referrer, *client
                        body: formData
                    });

                    if (await response.ok) {
                        if (await response.status !== 204) {
                          this.Dialog = "send";
                        }
                    } else {
                      this.Dialog = "error";
                    };

                } catch (error) {

                }
            }else { this.Dialog = 'invalid'; }

        },
        itemProps (item) {
          this.form.location = item.title;
          return {
            title: item.title
          }
        },
        formatPhoneNumber() {
                // Supprimer les tirets et autres caractères non numériques
            this.form.phone = this.form.phone.replace(/[^0-9]/g, '');
            let number  = 0; 
            let validNumber = true;

            try{number = Number(this.form.phone); } catch { validNumber= false;}            
                // Limiter la longueur du numéro à 9 chiffres
            if (this.form.phone.length > 11) {            
                this.form.phone = this.form.phone.substring(0, 11);
            } else if(this.form.phone.length == 0) { this.form.phone = 0; }
        },
    },
    component: {
      phone
    }
    
  }
</script>