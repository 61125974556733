<template>
    <div class="product">

      <!--TopBanner-->
      <section>
        <div class="topBanner d-flex align-center">
        <div class="bgBlack">
          <v-row>
            <v-col cols=12 class="d-flex justify-center align-center">
              <div class="d-flex justify-center align-center">
                <h1 v-html="$t('res.title')" class="topBannerLogo txt-c "></h1>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
      </section>

      <!--Details-->
      <section>
        <v-row class="gridpad">
          <v-col>
            <h2 class="mt-6" v-html="$t('res.address')"></h2>
          </v-col>
        </v-row>
        <!--
        <v-row style="margin: 50px -70px">
          <v-col class="pa-0" style="position:relative">
            <div style="height:185px; width:185px; position:absolute; right:-93px; bottom:-10%">
              <img v-if="Content.type == 'Commercial'" src="../../../public/ressources/images/house-key-svgrepo-res.svg" style="height:100%; width:100%"/>
              <img v-if="Content.type == 'Residential'" src="../../../public/ressources/images/house-key-svgrepo-com.svg" style="height:100%; width:100%"/>
            </div>
          </v-col>
          <v-col v-for="(item, i) in Content.Description.items" :key="i" class="pa-0 d-flex align-center justify-center borderKey" style="height: 150px;">
            <v-row class="ma-0 ">
              <v-col class="pa-0 d-flex flex-column align-center justify-center">
              <p class="f-primary bold">{{item.title}}</p>
              <p class="">{{item.details}}</p>
              </v-col>
            </v-row>
            <div class="borderKeyR"></div>
          </v-col>
          <v-col class="pa-0" style="position:relative">
            <div style="height:185px; width:185px; position:absolute; left:-93px; bottom:-10%">
              <img v-if="Content.type == 'Commercial'" src="../../../public/ressources/images/house-key-svgrepo-res-reverse.svg" style="height:100%; width:100%"/>
              <img v-if="Content.type == 'Residential'" src="../../../public/ressources/images/house-key-svgrepo-com-reverse.svg" style="height:100%; width:100%"/>
            </div>
          </v-col>
        </v-row>-->
      </section>

      <section>
        <v-row style="" class="detailsMobile">
          <v-col>
            <infoDetails v-model:item="Content.Description"/>
          </v-col>
        </v-row>
        
      </section>

      <!--Images-->
      <section>
        <v-row class="gridpad">
          <v-col class="d-flex justify-center align-center">
            <h2 class="uppercase" v-html="$t('res.imagesTitle')"></h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <rooms v-model:item="Content.ListImages"/>
          </v-col>
        </v-row>

        
      </section>

      <!--Description-->
      <section>
        <v-row class="gridpad">
          <v-col><h2 class="f-primary d-flex justify-sm-start justify-center" v-html="$t('description.title')"></h2></v-col>
        </v-row>
        
        <v-row class="gridpadForm">
          <v-col cols="12" md="6" class="txt-j px-6 ">
            <h3 class="roboto400" v-html="$t('description.subtitle')"></h3>
            <p class="roboto400 describeText" v-html="$t('description.text')"></p>
          </v-col>
          <v-col cols="12" md="6" class="txt-j px-6">
            <h3 class="roboto400" v-html="$t('description.subtitle')"></h3>
            <p class="roboto400 describeText" v-html="$t('description.text')"></p>
          </v-col>
          <v-col cols="12" md="6" class="txt-j px-6">
            <h3 class="roboto400" v-html="$t('description.subtitle')"></h3>
            <p class="roboto400 describeText" v-html="$t('description.text')"></p>
          </v-col>
          <v-col cols="12" md="6" class="txt-j px-6">
            <h3 class="roboto400" v-html="$t('description.subtitle')"></h3>
            <p class="roboto400 describeText" v-html="$t('description.text')"></p>
          </v-col>
        </v-row>
      </section>

      <!--Plan-->
      <section>
        <v-row class="gridpad">
          <v-col><h2 class="f-primary d-flex justify-sm-end justify-center" v-html="$t('img.title')"></h2></v-col>
        </v-row>

        <v-dialog width="80%" height="60%">
          <template v-slot:activator="{ props }">
            <v-row class="gridpad">
              <v-col class="d-flex align-center justify-center">
                <div class="borderPrimary" style="max-height:632px; max-width:942px;">
                  <img v-bind="props" :src="Content.plan" style="height:100%; width:100%"/>
                </div>
              </v-col>
            </v-row>
         </template>

        <template v-slot:default="{ isActive }">
          <v-card>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text="X"
                @click="isActive.value = false"
              ><span class="bold" style="font-size: 22px;">X</span></v-btn>
            </v-card-actions>
            <v-card-text>
              <v-row class="">
              <v-col class="d-flex align-center justify-center">
                <div class="borderPrimary" style="max-height:100%; max-width:100%;">
                  <img :src="Content.plan" style="height:100%; width:100%"/>
                </div>
              </v-col>
            </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
      </section>
      
      <!--Formulaire-->
      <section class="gridpadForm">
        <formulaire v-model:item="Content.form"/>
      </section>
  
  </div>
</template>
  
<style scoped>
  .detailsMobile {margin: 50px -70px}
  .borderPrimary { border: 7px solid rgb(var(--primary)) !important;}
  .borderPrimary:hover { box-shadow:0px 0px 10px black;}
  .borderKey { border-top: 1px solid rgb(var(--primary)) !important; border-bottom: 1px solid rgb(var(--primary)) !important; height:150px;}
  .borderKeyR { border-right: 1px solid rgb(var(--primary)) !important; height:80%; margin: 15px 0px !important;}
  .topBanner {position: relative; height: 500px;}
  .topBanner::before {
    content: ' '; 
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background-image: url('../../../public/ressources/images/locations/test/2315rueSt-Pierre.jpg');
    background-position: center;
    background-position-y: 55%;
    background-repeat: no-repeat; 
    background-size: cover; 
  }
  .bgBlack {
    position:relative ; background-color: rgba(0,0,0,0); z-index:1000; width:100%; margin: 0vw 0px 0vw 0px;
  }
  .topBannerLogo {
    color: rgb(var(--tierce));
    text-shadow: 4px 4px 4px #BDA947;
    font-size: calc(7vw) !important;
    font-weight: 600;
    line-height: normal;
  }
  .describeText {text-align: justify; font-size: 20px;
font-style: normal;
font-weight: 300;
line-height: normal;}

@media handheld, only screen and (max-width: 400px) {
    .detailsMobile {margin: 50px 0px !important;}
  }
  @media handheld, only screen and (max-width: 600px) {
    .detailsMobile {margin: 50px -30px;}
  }
  @media handheld, only screen and (max-width: 960px) {
    .topBanner {height: 250px;}
    .topBanner::before {
    width: 160%;
    background-position-y: 50%;

}
  }
  @media handheld, only screen and (max-width: 1264px) {}  
</style>

<script>
  // @ is an alias to /src
  import translations from '../../../public/ressources/i18n/locationTest.json';
  import formulaire from '../../components/form.vue';
  import rooms from '../../components/rooms.vue';
  import infoDetails from '../../components/details.vue';
  
  export default {
    name: 'resTest',
    data: () => ({
      Content: {
        address: '275 chemin yamaska, saint-germain-de-grantham QC',
        type:'Residential', // Residential, Commercial
        img:'ressources/images/maisonDiapo.jpg',
        style:'Appartement',
        detailsTitle: {
          rooms: 'Chambres',
          bathrooms: 'Salles de bain',
          pets: 'Animaux',
          smoker: 'Fumeur'
        },
        Description: {
          type:'Residential',
          items: [
            { title: 'Chambres', details: '1 à 3 chambres'},
            { title: 'Salles de bain', details: '1 salle de bain'},
            { title: 'Animaux', details: 'interdit'},
            { title: 'Fumeur', details: 'Non fumeur'},  
          ]
        },
        ListImages: [
          {id: 0, type: 'Chambre', img:'ressources/images/imgModelTest.jpg'},
          {id: 1, type: 'Cuisine', img:'ressources/images/imgModelTest.jpg'},
          {id: 2, type: 'Salon', img:'ressources/images/imgModelTest.jpg'},
          {id: 3, type: 'Salle de bain', img:'ressources/images/imgModelTest.jpg'},
        ],
        webLink:'/resTest',
        plan: 'ressources/images/plan.jpg',
        form: {id: 0, selected:true}
      },
      
    }),
    mounted() {
      this.$i18n.mergeLocaleMessage('fr', translations.fr);
      this.$i18n.mergeLocaleMessage('en', translations.en);
    },
    methods:{
      switchLanguage: function () { this.$i18n.locale = this.currentLang() === 'en' ? 'fr' : 'en'; },
      currentLang() { return this.$i18n.locale; },
    },
    components: {
      formulaire,
      rooms,
      infoDetails
    }
  }
</script>
  