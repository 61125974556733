<template>
  <v-app>
    <v-main class="pa-0">
      <Header />
      <div class="fixHeader"><router-view/></div>    
      <Footer />
    </v-main>
  </v-app>


</template>

<style>
#app {
    background-color: var(--[v-background-base]);
}

.fixHeader {margin-top: 150px;}
@media handheld, only screen and (max-width: 600px) {}
@media handheld, only screen and (max-width: 960px) {
  .fixHeader {margin-top: 105px !important;}
}
@media handheld, only screen and (max-width: 1279px) {}
@media handheld, only screen and (max-width: 1378px) {}
</style>
<script>


export default {
  name: 'App',
}
</script>
