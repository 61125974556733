<template>
  <div v-if="ready">
      <v-carousel cycle :show-arrows="false" hide-delimiters infinite style="max-height: 450px; padding-top: 20px;">
        <template  v-for="(item, index) in current">
          <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index">
            <v-row class="flex-nowrap" style="height:84%">
              <template v-for="(n,i) in columns">
                <template v-if="(+index + i) < current.length">
                  <v-col :key="i">
                    <v-sheet v-if="(+index + i) < current.length" height="100%" class="pt-8">
                      <v-row justify="center">
                          <!--ici ton image ou information necessaire-->
                          <v-container class="productBox " style="" @click="selectedRoom(current[index + i])"> <!--v-if="item.id != 3"  v-if="item.promo == false"-->
                            <v-row class="ma-0 d-flex flex-column borderPrimary">
                              <v-col class="pa-0" style="position: relative;">
                                <div class="productTitleBox">
                                  <p class="txt-c productTitle" v-html="current[index + i].type"></p>
                                </div>
                                <div class="product d-flex align-center justify-center" style="height: 330px; position: relative;">
                                  <img v-if="current[index + i].loan" src="/ressources/images/keyLoan.png" truesrc="/ressources/images/keyLoan.png" style="position: absolute; top: 50%; left: 50%; width: 75%; height: auto; transform: translate(-50%,-50%);" />
                                  <img :src="current[index + i].img" :truesrc="current[index + i].img" style="height:100%; width:auto; "/>
                                </div>
                              </v-col>
                            </v-row>
                            <v-row class="ma-0 d-flex flex-column"><v-col class="pa-0"><h2 class="txt-c price" style="height: 10px;" v-html="current[index + i].price"></h2></v-col></v-row>
                          </v-container>
                      </v-row>
                    </v-sheet>
                  </v-col>
                </template>
              </template>
            </v-row>
          </v-carousel-item>
        </template>
      </v-carousel>
      <v-dialog width="80%" height="80%" style="z-index: 10000;" v-model="showSlectedImage">
        <template v-slot:default="{ isActive }">
          <v-card>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text="X"
                @click="isActive.value = false"
              ><span class="bold" style="font-size: 22px;">X</span></v-btn>
            </v-card-actions>
            <v-card-text>
              <v-row><v-col><h2 class="f-primary">{{ LocationSelected.type }} <span style="font-size: 24px; color: black;">({{LocationSelected.price}})</span></h2></v-col></v-row>
              <v-row class="">
                
                <v-col class="d-flex align-center justify-center">
                  <div class="borderPrimary" style="max-height:100%; max-width:100%; position: relative;">
                    <!--<img v-if="LocationSelected.loan"  src="/ressources/images/keyLoan.svg" truesrc="/ressources/images/keyLoan.svg" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%);" />-->
                    <img :src="LocationSelected.img" style="height:100%; width:100%"/>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
    </div>
</template>
<style scoped>
    .borderPrimary { border: 7px solid rgb(var(--primary)) !important;}
    .storeGridpad {padding: 25px 25px 0px 25px;}
    .product {  height: auto; width: auto; position: relative; background-color: white; background-position: center center; background-repeat: no-repeat; background-size: cover; display: block; margin: auto auto !important; cursor: pointer; overflow: hidden;}
    .productContainer {max-height: 1000px; min-height: 400px; overflow-x: scroll;}
    .productContainer::-webkit-scrollbar { width: 1px !important;}
    .productTitleBox {position: absolute; background-color: rgba(var(--tierce), 0.6); width: auto; height: auto; z-index: 100; padding:5px 10px; top:10px;}
    .productTitle {font-family: Playfair Display; font-size: 20px; font-style: normal; font-weight: 600; line-height: normal;}
    .product {  height: auto; width: auto; position: relative; background-color: white; background-position: center center; background-repeat: no-repeat; background-size: cover; display: block; margin: auto auto !important; cursor: pointer; overflow: hidden;}
    .productBox {height: 100%; width: 100%; min-width: 325px; max-width: 375px; padding: 0px; cursor: pointer;}
    .productBox:hover .borderPrimary {box-shadow:0px 0px 10px black;}
    .productBox:hover .price {color: rgb(var(--primary)) !important;}
    .productHover{position: absolute; background-color: rgba(var(--tierce), 0.6); width: 100%; height: 30%; visibility: hidden; z-index: 100; bottom:0px}
    .styleProductHover{width: 100%; margin:5px;}
    .detailsProductHover{width: 100%;}
    .productBox:hover .productHover{visibility: visible;}
    @media handheld, only screen and (max-width: 380px) {
      .productBox {min-width: 275px !important; max-width: 275px !important;}
    }
    @media handheld, only screen and (max-width: 500px) {
      .productBox {min-width: 325px; max-width: 350px;}
    }
    @media handheld, only screen and (max-width: 600px) {}
    @media handheld, only screen and (max-width: 960px) {}
    @media handheld, only screen and (max-width: 1264px) {}  
</style>
<script>
    import pageTranslations from '../../public/ressources/i18n/global.json';

  export default {
    name: 'Locations',
    props: {
        item: Object
    },
    data: () => ({
      columns: 3,
      current: null,
      showSlectedImage: false,
      LocationSelected: {
        id: 0, 
        type: 'Appartement rez de chaussée', 
        img:'ressources/images/locations/StPierre/appartement1.jpg',
      }
    }),
    mounted() {
      this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      this.$i18n.mergeLocaleMessage('en', pageTranslations.en);

    },
    created() {
      window.addEventListener("resize", this.changeColumns);
    },
    destroyed() {
      window.removeEventListener("resize", this.changeColumns);
    },
    computed:{
        ready: async function () {
            let show = false;
            if(this.item) {
                this.current = this.item;
                show = true;
            }
            return show;
        },

    },
    methods:{
      changeColumns() {
        let width = window.innerWidth;
        if (width > 1800) { this.columns = 4; }
        else if (width > 1200) { this.columns = 3; }
        else if (width > 800) { this.columns = 2; } 
        else if (width < 800) { this.columns = 1; } 
      },
      navigateTo: function (item) {this.$router.push(item.webLink);},
      selectedRoom: function(item) {
        this.LocationSelected = item;
        this.showSlectedImage = true;
      }
    },
    component: {

    }
    
  }
</script>