
<template>
    <main class="footer">
        <v-row class="d-flex flex-sm-row flex-column-reverse">
            <v-col class="d-flex justify-sm-start justify-center align-center">
                <p class="" to="/">{{ $t('footer.text') }}</p> 
            </v-col>
            <v-col class="d-flex justify-center justify-sm-end align-center">
                <a href="/"><img style="height: 80px; width: auto;" src="../../public/ressources/images/logo/logo.svg" /></a>
            </v-col>
        </v-row>
    </main>  
</template>
<style scoped>
    .footer {overflow: hidden; padding: 0px 20px 0px 20px; position: relative; bottom: 0; width: 100%; background-color: rgb(var(--backgroundFooter));}
    @media handheld, only screen and (max-width: 450px) {}
    @media handheld, only screen and (max-width: 600px) {}
    @media handheld, only screen and (max-width: 960px) {}
    @media handheld, only screen and (max-width: 1279px) {}
    @media handheld, only screen and (max-width: 1378px) {}
</style>
<script>
import translations from '../../public/ressources/i18n/global.json';
export default {
    name: 'Footer',
    data: () => ({}),
    mounted() {
        this.$i18n.mergeLocaleMessage('fr', translations.fr);
        this.$i18n.mergeLocaleMessage('en', translations.en);
    },
    methods: {},
    component: {}
}
</script>