<template>
    <div class="product">

      <!--TopBanner-->
      <section>
        <div class="topBanner"></div>
      </section>

      <!--Details-->
      <section>
        <v-row class="gridpad">
          <v-col>
            <h2 class="mt-6" v-html="$t('res.address')"></h2>
          </v-col>
        </v-row>
      </section>
      <section class="gridpadForm">
        <v-row>
          <v-col><h2 class="f-primary txt-c">Notre local commercial offre une opportunité exceptionnelle à un tarif très compétitif.</h2></v-col>
        </v-row>
      </section>
      <section>
        <v-row style="" class="detailsMobile">
          <v-col>
            <infoDetails v-model:item="Content.Description"/>
          </v-col>
        </v-row>
        
      </section>

      <!--Plan-->
      <section>
        <v-row class="gridpad">
          <v-col><h2 class="f-primary d-flex justify-sm-end justify-center" v-html="$t('img.title')"></h2></v-col>
        </v-row>

        <v-dialog width="80%" height="80%" style="z-index: 10000;">
          <template v-slot:activator="{ props }">
            <div>
              <v-row class="gridpad">
                <v-col class="d-flex align-center justify-center">
                  <div class="borderPrimary" style="max-height:632px; max-width:942px;">
                    <img v-bind="props" :src="Content.plan" style="height:100%; width:100%"/>
                    <h2 class="txt-c price" style="padding-top: 20px;" v-html="Content.price"></h2>
                  </div>
                </v-col>
              </v-row>
              <v-row class="ma-0 d-flex flex-column"><v-col class="pa-0"></v-col></v-row>
            </div>

         </template>

        <template v-slot:default="{ isActive }">
          <v-card>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text="X"
                @click="isActive.value = false"
              ><span class="bold" style="font-size: 22px;">X</span></v-btn>
            </v-card-actions>
            <v-card-text>
              <v-row class="">
                <v-col class="d-flex align-center justify-center">
                  <div class="" style=" max-height:100%; max-width:100%;">
                    <img :src="Content.plan" style="height:auto; width:100%"/>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
      </section>

      <!--Description-->
      <section>
        <v-row class="gridpad">
          <v-col><h2 class="f-primary d-flex justify-sm-start justify-center" v-html="$t('description.title')"></h2></v-col>
        </v-row>
        
        <v-row class="gridpadForm">
          <v-col class="txt-j px-6 ">
            <p class="roboto400 describeText">Ce local d'une superficie de 1 270 pieds carrés dispose d'une hauteur de plafond de 10 pieds net intérieur, incarne l'élégance et le potentiel pour votre entreprise. Situé dans un emplacement stratégique, il est doté d'un espace de stationnement dédié qui assure une grande facilité d'accès pour vos clients.</p>
            <p class="roboto400 describeText">En entrant, vous serez immédiatement impressionné par la modernité et la luminosité de l'intérieur. Les grandes fenêtres qui encadrent l'espace inondent la pièce de lumière naturelle, créant ainsi une atmosphère accueillante et invitante. Ces fenêtres offrent également une visibilité optimale depuis l'extérieur, vous permettant de mettre en valeur vos produits ou services de manière attrayante. </p>
            <p class="roboto400 describeText">Le loyer commercial est fixé à 15$/pi2, auquel s'ajoutent les frais communs ainsi les taxes non résidentielles. </p>
          </v-col>
        </v-row>
      </section>
      
      <!--Formulaire-->
      <section class="gridpadForm" id="FormHome">
        <formulaire v-model:item="Content.form"/>
      </section>
  
  </div>
</template>
  
<style scoped>
  .detailsMobile {margin: 50px -70px}
  .borderPrimary { border: 7px solid rgb(var(--primary)) !important;}
  .borderPrimary:hover { box-shadow:0px 0px 10px black;}
  .borderKey { border-top: 1px solid rgb(var(--primary)) !important; border-bottom: 1px solid rgb(var(--primary)) !important; height:150px;}
  .borderKeyR { border-right: 1px solid rgb(var(--primary)) !important; height:80%; margin: 15px 0px !important;}
  .topBanner {position: relative; height: 500px;}
  .borderPrimary:hover .price {color: rgb(var(--primary));}
  .topBanner::before {
    content: ' '; 
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background-image: url('../../../public/ressources/images/building.jpg');
    background-position: center;
    background-position-y: 55%;
    background-repeat: no-repeat; 
    background-size: cover; 
  }
  .bgBlack {
    position:relative ; background-color: rgba(0,0,0,0); z-index:1000; width:100%; margin: 0vw 0px 0vw 0px;
  }
  .topBannerLogo { color: rgb(var(--tierce)); text-shadow: 4px 4px 4px #BDA947; font-size: calc(7vw) !important; font-weight: 600; line-height: normal; }
  .describeText {text-align: justify; font-size: 20px; font-style: normal; font-weight: 300; line-height: normal; padding: 20px 0px;}

@media handheld, only screen and (max-width: 400px) {
    .detailsMobile {margin: 50px 0px !important;}
  }
  @media handheld, only screen and (max-width: 600px) {
    .detailsMobile {margin: 50px -30px;}
  }
  @media handheld, only screen and (max-width: 960px) {
    .topBanner {height: 250px;}
    .topBanner::before {
    width: 160%;
    background-position-y: 50%;

}
  }
  @media handheld, only screen and (max-width: 1264px) {}  
</style>

<script>
  // @ is an alias to /src
  import translations from '../../assets/i18n/commercialStPierre.json';
  import formulaire from '../../components/form.vue';
  import rooms from '../../components/rooms.vue';
  import infoDetails from '../../components/details.vue';
  
  export default {
    name: 'resTest',
    data: () => ({
      Content: {
        address: '2295 - 2315 rue St-Pierre, Drummondville, Québec, J2C 4A7',
        type:'Commercial', // Residential, Commercial
        img:'ressources/images/maisonDiapo.jpg',
        price:'15$/pi2 + frais',
        style:'Commercial',
        detailsTitle: {
          rooms: 'Chambres',
          bathrooms: 'Salles de bain',
          pets: 'Animaux',
          smoker: 'Fumeur'
        },
        Description: {
          type:'Commercial',
          items: [
            { title: 'Espace', details: '1270 pi carré'},
            { title: 'Salles de bain', details: '1 salle de bain'},
            { title: 'Disponibilité', details: 'mars 2024'},
          ]
        },
        ListImages: [
          {id: 0, price:'15$/pi2 + frais', type: 'Local commercial', img:'ressources/images/locations/StPierre/commerciaux1.jpg'}
        ],
        webLink:'/resTest',
        plan: 'ressources/images/locations/StPierre/commerciaux1.jpg',
        form: [
          {title: 'Local commercial'}
        ]
      },
      
    }),
    mounted() {
      this.$i18n.mergeLocaleMessage('fr', translations.fr);
      this.$i18n.mergeLocaleMessage('en', translations.en);
    },
    methods:{

    },
    components: {
      formulaire,
      rooms,
      infoDetails
    }
  }
</script>
  