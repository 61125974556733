<script setup>

</script>
<template>
    <div>
        <div class="d-none d-md-flex"> <!--d-none d-md-flex-->
            <div class="header">
                <v-row class="ma-0">
                    <v-col class="pa-0" style="max-width: 190px; min-width: 190px;">
                        <router-link to="/"><div class="logo"></div></router-link>
                    </v-col>
                    
                    <v-col cols="6" class="d-flex justify-start align-end pa-0">
                        <router-link class="menuTxt" to="/residential">{{ $t('menu.res') }}</router-link> <!--tu dois pas oublier de mettre les bon id-->
                        <router-link class="menuTxt" to="/commercial">{{ $t('menu.com') }}</router-link>
                        <a class="menuTxt" href="#FormHome">{{ $t('menu.reserve') }}</a>
                    </v-col>
                    <v-col class="pa-0 d-flex justify-end">
                        <a class="F-IE" href="https://www.facebook.com/" target="_Blank"><div class="smIcon"></div></a>
                        <P class="f-primary" style="position: absolute; bottom: 0; right: 0; padding: 10px;">DISPONIBLE EN MARS 2024</P>
                    </v-col>
                    <!--<v-col class="pa-0">
                        <a class="" v-if="this.$i18n.locale != 'en'" @click="switchLanguage()">EN</a><a class="menu F-IE" v-if="this.$i18n.locale != 'fr'" @click="switchLanguage()">FR</a>
                    </v-col>-->
                </v-row>
                
            </div>
        </div>

        <!--Mobile menu-->
        <div class="d-flex d-md-none">
            <div class="headerM">
            <v-row no-gutters>
                <v-app-bar class="menuM v-primary-light-4" style="overflow-x: hidden;">
                    <v-row class="ma-0">
                        <v-col cols="2" sm="1" class="d-flex align-center justify-start"><img style="width:35px; height:35px" @click.stop="this.drawer = !this.drawer" src="../../public/ressources/images/icon/menu.svg"/></v-col>
                        <v-col cols="8" sm="10" class="pa-0 d-flex align-center justify-center">
                            <router-link to="/"><div class="logo2"></div></router-link>  
                        </v-col>
                        <!--<v-col  cols="2" sm="1" class="d-flex align-center justify-end pr-6">
                            <a class="langBtn F-IE" v-if="this.$i18n.locale != 'en'" @click="switchLanguage()">EN</a><a class="langBtn F-IE" v-if="this.$i18n.locale != 'fr'" @click="switchLanguage()">FR</a>
                        </v-col>-->
                        <v-col cols="2" sm="1" class="pa-0 d-flex justify-end align-center">
                            <a class="F-IE" href="https://www.facebook.com/" target="_Blank"><div class="smIcon"></div></a>   
                        </v-col>
                    </v-row>
                </v-app-bar>

                <v-navigation-drawer class="drawer" v-model="this.drawer" location="top" style="">
                    <v-list>
                        <v-list-item class="pa-0 ma-0"><router-link class="menuTxt" to="/residential">{{ $t('menu.res') }}</router-link></v-list-item>
                        <v-list-item class="pa-0 ma-0"><router-link class="menuTxt" to="/commercial">{{ $t('menu.com') }}</router-link></v-list-item>
                        <v-list-item class="pa-0 ma-0"><a class="menuTxt" href="/#FormHome">{{ $t('menu.reserve') }}</a></v-list-item>
                        <v-list-item class="f-primary">DISPONIBLE EN MARS 2024</v-list-item>
                    </v-list>
                </v-navigation-drawer>         
            </v-row>
        </div>
        </div>
    </div>
    
</template>
<style scoped>
    .menu { text-transform:uppercase; color: rgb(var(--secondary)); font-weight: 700; font-size: small;}
    .menu:hover {color: rgb(var(--primary)); text-decoration: none; cursor: pointer;} 
    .menuM { padding: 20px 10px; width: 100%;}
    .header {position: fixed; top: 0px; height: 150px; width: 100%; background-color: rgb(var(--backgroundHeader)); z-index:10000; box-shadow: 0px 0px 15px rgb(var(--primary));}
    .headerM {position: fixed; top: 0px; height: 105px; width: 100%; background-color: rgb(var(--backgroundHeader)); z-index:10000; box-shadow: 0px 0px 15px rgb(var(--primary));}
    .menuTxt {font-family: Playfair Display; font-size: 18px; font-style: normal; font-weight: 400; line-height: normal; margin: 10px 10px;}
    .menuTxt:hover {color: rgb(var(--primary)) !important;}
    .logo {height: 145px; width: 190px; margin: auto auto; background-image: url('../../public/ressources/images/logo/logo.svg');}
    .logo2 {height: 105px; width: 140px; margin: auto auto; background-image: url('../../public/ressources/images/logo/logo.svg'); margin-bottom: 5px;}
    .logo:hover{background-image: url('../../public/ressources/images/logo/logo-hover.svg');}
    .logo2:hover{background-image: url('../../public/ressources/images/logo/logo-hover.svg');}
    .itemHover {opacity:0;}
    .itemHover:hover {opacity:1;}
    .smIcon{height: 40px; width: 40px; margin: 10px 10px; background-image: url(./../../public/ressources/images/icon/Facebook.svg);}
    .smIcon:hover{background-image: url(./../../public/ressources/images/icon/Facebook-hover.svg);}
    .drawer { padding-top: 40px !important; width:50% !important; height: 100% !important;}

    @media handheld, only screen and (max-width: 450px) {}
    @media handheld, only screen and (max-width: 600px) {}
    @media handheld, only screen and (max-width: 800px) {} /*menu mobile*/
    @media handheld, only screen and (max-width: 960px) {}
    @media handheld, only screen and (max-width: 1279px) {}
    @media handheld, only screen and (max-width: 1378px) {}
</style>
<script>
import translations from '../../public/ressources/i18n/global.json';
export default {
    name: 'Header',
    data: () => ({
        drawer: false,
    }),
    mounted() {
        this.$i18n.mergeLocaleMessage('fr', translations.fr);
        this.$i18n.mergeLocaleMessage('en', translations.en);
    },
    methods: {
        switchLanguage: function () { this.$i18n.locale = this.currentLang() === 'en' ? 'fr' : 'en'; },
        currentLang() { return this.$i18n.locale; },
    },
    component: {}
}
</script>