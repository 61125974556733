import './../public/app_Themes/main.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createI18n } from 'vue-i18n'

const i18n = createI18n({
    locale: 'fr',
    allowComposition: true, // you need to specify that!
    messages: {
      en: {
        hello: 'page Home.'
      },
      fr: {
        hello: 'page accueil.'
      }
    }
})
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

//createApp(App).use(router).mount('#app')
import header from './components/header.vue'
import footer from './components/footer.vue'

const app = createApp(App)
app.component('Header', header);
app.component('Footer', footer);

const vuetify = createVuetify({
    components,
    directives,
});
app.use(vuetify);

app.use(router)
app.use(i18n)
app.mount('#app')
//createApp(App).use(router).mount('#app')
